.main-container {
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.overlay-container {
  position: relative;
  height: 230px;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-text {
  position: absolute;
  bottom: 45px;
  left: 45px;
  z-index: 1;
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-container {
  .project-section {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: column;
    .section-heading {
      font-size: 64px;
      font-weight: 800;
      align-self: start;
    }
    .section-description {
      font-size: 24px;
    }
    .images-container {
      display: flex;
      gap: 16px;
      padding: 28px;
      flex-wrap: wrap;
      justify-content: center;
      .image-container {
        height: 300px;
        width: 300px;

        .image-asset {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
        .image-asset:hover {
          cursor: pointer;
          filter: drop-shadow(0 0 0.2rem black);
        }
      }

      .image-container-for-one {
        height: 100%;
        width: 100%;

        .image-asset {
          height: 100%;
          width: 100%;
        }

        .image-asset:hover {
          filter: drop-shadow(0 0 0.2rem black);
        }
      }

      .video-container-for-one {
        height: 20%;
        width: 20%;

        filter: drop-shadow(0 0 0.2rem black);

        @media (max-width: 480px) {
          width: 100%;
        }
      }

      .image-container-for-two {
        display: flex;
        height: 550px;
        width: 550px;

        .image-asset {
          height: 100%;
          width: 100%;
        }

        .image-asset:hover {
          filter: drop-shadow(0 0 0.2rem black);
        }

        @media (max-width: 480px) {
          height: 280px;
          width: 280px;
        }
      }

      // .image-asset {
      //   height: 540px;
      //   width: 675px;
      //   box-shadow: 10px 5px 5px red;
      //   object-fit: contain;
      // }
    }
  }
}
