.passCheck {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    input {
        outline: none;
        font-size: 2rem;
        border: none;
        background: transparent;
        border: 1px solid grey;
        height: 40px;
        width: 300px;
        border-radius: 48px;
        padding: 0 1rem;
        mask-type: square;

    }

    ::-webkit-input-placeholder {
        font-size: 1rem;
        position: relative;
        top: -5px;
    }

    img {
        width: 40px;
        height: 40px;
        margin-left: 20px;
        cursor: pointer;
    }
}