.footer {
  justify-content: right;
  flex-direction: column;
  background-color: #f9f5e7;
  position: sticky;
  top: 0;
  z-index: 10;
  border-top: 3px solid white;
  .componentTitle {
    text-align: right;
    padding-bottom: 2rem;
  }
  .footer_content {
    padding: 2rem;
    text-align: center;
    @media screen and (max-width: 500px) {
      padding: 1rem;
    }
  }
}
