* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  scroll-behavior: smooth;
  // position: relative;

  // cursor: none;
}

body {
  background: #f7f3e5;
  font-family: "Lato", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  word-spacing: 2px;
  letter-spacing: -1px;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.container {
  padding: 0 5rem;
  display: flex;

  // justify-content: center;
  @media screen and (max-width: 806px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 600px) {
    padding: 0 1rem;
    justify-content: flex-start;
  }
}

.timeLine {
  font-weight: bold;
  padding-top: 3rem;
}

h1 {
  font-weight: 700;
  font-size: 7rem;
  color: rgba(36, 36, 36, 1);

  @media screen and (max-width: 806px) {
    font-size: 5.5rem;
  }

  @media screen and (max-width: 550px) {
    font-size: 4.5rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 3rem;
  }
}

// animation
.hide {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: black;
}
// preloader
/* preloader */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 45612845465454657;
  background-color: #f9f5e7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader .box {
  width: 65px;
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.preloader .box div {
  height: 15px;
  width: 15px;
  background-color: black;
  border-radius: 50%;
  animation: loaderAni 1s ease infinite;
}
.preloader .box div:nth-child(2) {
  animation-delay: 0.1s;
}
.preloader .box div:nth-child(3) {
  animation-delay: 0.2s;
}
.preloader.fade-out {
  opacity: inset 0 0 0 #d0d0d0, inset 0 0 0 #f8f8f8;
  transition: all 0.6s ease;
}
@keyframes loaderAni {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}

.preview-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(6px);
  

  img {
    height: 650px;
    width: 700px;
    object-fit: contain;
    border-radius: 12px;
    padding: 2rem;

    @media (max-width: 480px) {
      padding: 1rem;
      height: 100%;
      width: 100%;
    }
  }
}

@import "./nav";
@import "./home";
@import "./about";
@import "./services";
@import "./project";
@import "./client";
@import "./contact";
@import "./footer";
@import "./projectPage";
@import "./passcheck";
@import "./fleurologu"; // @import "./cursor";
