nav {
  height: 15vh;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  opacity: 1;
  transform: none;
  // border-bottom: 2px solid #fff;
  background: #f9f5e7;
  z-index: 10;

  .left_nav,
  .right_nav {
    display: flex;
    padding: 0 3rem;
    @media screen and (max-width: 902px) {
      padding: 0 1.5rem;
    }
    li {
      list-style: none;
      padding: 3rem;
      font-size: 1.5rem;
      font-weight: 500;
      @media screen and (max-width: 806px) {
        font-size: 1rem;
        padding: 1rem;
      }
      a {
        text-decoration: none;
        color: black;
      }
    }
    .back {
      background: url("../assets/menu.svg") no-repeat center;
      @media screen and (max-width: 806px) {
        width: unset;
      }
    }
  }
  @media screen and (max-width: 806px) {
    justify-content: space-around;
    // height: 10vh;
  }
}
.desktop {
  @media screen and (max-width: 806px) {
    display: none;
  }
}

// menu
.navMenu {
  position: absolute;
  top: 0;
  left: 0;
}
.menuItem {
  background-color: #f9f5e7;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  top: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  line-height: 78px;
  padding: 2rem;
  @media screen and (max-width: 806px) {
    top: 0px;
  }
}
.button {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-size: 2rem;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  @media screen and (max-width: 806px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  p {
    display: inline;
  }
}
.phnNav {
  display: none !important;
  @media screen and (max-width: 806px) {
    display: flex !important;
  }
}
