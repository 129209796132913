.headerWithImage {
    display: flex;

    @media screen and (max-width: 806px) {
        flex-direction: column;
    }

    .para {
        flex: 0 0 70%;
        margin-right: 3rem;

        @media screen and (max-width: 806px) {
            margin-right: 0;
        }
    }

    .adzonepara {
        flex: 0 0 48%;
    }

    .headingVid {
        flex: 0 0 25%;
        margin: 0.5rem;

        video {
            width: 100%;
        }
    }
}