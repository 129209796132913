.aboutSection {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f5e7;
  position: sticky;
  top: 0;
  justify-content: center;
  // padding-top: 2rem;
  border-top: 3px solid white;
  z-index: 6;

  .timeLine {
    padding-top: 0rem;
  }

  .about {

    // padding-top: 5rem;
    @media screen and (max-width: 700px) {
      padding-top: 3rem;
    }

    p {
      width: 65%;
      font-size: 2rem;

      @media screen and (max-width: 806px) {
        font-size: 1.5rem;
        line-height: 1.5;
        width: 100%;
      }
    }

    .text2 {
      font-family: "IBM Plex Mono", monospace;
      width: 60%;
      padding-top: 2rem;
      font-size: 1.2rem;
      text-align: justify;

      @media screen and (max-width: 806px) {
        width: 100%;
        font-size: 1rem;
      }
    }
  }
}