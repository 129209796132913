.projectBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #f9f5e7;

  a {
    flex: 0 0 33.333333%;
  }

  .projectContent {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    padding: 1rem;
    margin: 1rem;

    .heading {
      text-transform: uppercase;
      color: black;
    }

    .imageBox {
      height: 390px;

      span {
        width: 100%;
      }

      @media screen and (max-width: 550px) {
        width: 85vw;
      }

      img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        margin: 1rem 0;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
    .containImg{
      img{
        object-fit: contain !important;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  span {
    margin: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active{
    background-color: #242424;
    color: white;
  }
}