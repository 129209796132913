.clients {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  background-color: #f9f5e7;
  border-top: 3px solid white;
  z-index: 7;
  position: sticky;
  // top: 0;
  justify-content: unset;

  .componentTitle {
    display: flex;
    justify-content: right;
  }

  .client_title {
    h1 {
      text-transform: uppercase;
      font-size: 5.5rem;

      @media screen and (max-width: 700px) {
        font-size: 3rem;
      }
    }
  }

  .clients_section {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    .client_title_tab {
      flex: 1 1 0%;

      p {
        font-size: 2rem;
        // width: 50%;
        padding-top: 1rem;

        @media screen and (max-width: 750px) {
          width: 100%;
          // padding: 1rem;
          font-size: 1.5rem;
          line-height: 1.5;
        }
      }
    }

    .tab {
      flex: 1 1 0%;
      flex-wrap: wrap;
      height: fit-content;
      justify-content: end;
    }
  }

  .tab {
    display: flex;

    li {
      display: inline-block;
      list-style: none;
      padding: 0.5rem;
      border: 1px solid rgb(156, 156, 156);
      border-radius: 1rem;
      font-size: 1.5rem;
      margin: 0.5rem;
      cursor: pointer;

      @media screen and (max-width: 750px) {
        font-size: 1rem;
      }
    }
  }
}