.contact {
  justify-content: left;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f5e7;
  position: sticky;
  top: 0;
  border-top: 3px solid white;
  z-index: 9;

  .contact_us {
    padding-top: 5rem;

    @media screen and (max-width: 900px) {
      padding-top: 3rem;
    }

    h1 {
      text-align: right;

      @media screen and (max-width: 400px) {
        text-align: center;
      }
    }
  }

  .links {
    display: flex;
    justify-content: space-between;
    min-height: 50vh;
    align-items: center;

    @media screen and (max-width: 400px) {
      flex-direction: column;
    }

    .mail {
      background-color: black;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        color: white;
        text-decoration: none;
        font-size: 2rem;
        background: black;
      }
    }

    .social_link {
      display: flex;
      flex-direction: column;
      text-align: right;

      @media screen and (max-width: 700px) {
        text-align: left;
      }

      a {
        color: black;
        font-size: 2rem;
        text-decoration: none;
        padding: 1rem;
      }
    }
  }
}