.figo {
  padding: 2rem;

  @media screen and (max-width: 550px) {
    padding: 1rem;
  }

  h1 {
    padding-top: 2rem;
    font-size: 5rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 550px) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    padding-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    text-align: justify;

    @media screen and (max-width: 550px) {
      font-size: 0.9rem;
    }
  }

  .backImage::before {
    position: absolute;
    content: "";
    display: block;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 500px;
    background: var(--dynamic-background) no-repeat center center/cover;

    @media screen and (max-width: 550px) {
      height: 300px;
    }
  }

  .ambImgBef::before {
    background: url(../assets/projects/amb/brand/1.jpg) no-repeat center center/cover;
  }

  .rayImgBef::before {
    background: url(../assets/projects/ray/paper/1.jpg) no-repeat center center/cover;
  }

  .soilImgBef::before {
    background: url(../assets/projects/soil/02-min.jpeg) no-repeat center center/cover;
  }

  .shukranImgBef::before {
    background: url(../assets/projects/shukran/IMG_7581.JPG) no-repeat center center/cover;
  }

  .archiImgBef::before {
    background: url(../assets/projects/archi/a6.jpg) no-repeat center center/cover;
  }

  .coneImgBef::before {
    background: url(../assets/projects/cone/c1.jpg) no-repeat center center/cover;
  }

  .goyaImgBef::before {
    background: url(../assets/projects/goya/pack1.png) no-repeat center center/cover;
  }

  .jewarImgBef::before {
    background: url(../assets/projects/jewar.jpg) no-repeat center center/cover;
  }

  .fluImgBef::before {
    background: url(../assets/projects/fleurology/3d/Poster5.png) no-repeat center center/cover;
  }

  .adzImgBef::before {
    background: url(../assets/projects/adzone/3.png) no-repeat center center/cover;
  }

  .goyasocialImgBef::before {
    background: url(../assets/projects/goyasocial/month1/image/3.png) no-repeat center center/cover;
  }

  .trbImgBef::before {
    background: url(../assets/projects/trb/social/3.jpeg) no-repeat center center/cover;
  }

  .coffeaImgBef::before {
    background: url(../assets/projects/coffea/COFFEA/M1/P12.png) no-repeat center center/cover;
  }
  .soilcampImgBef::before {
    background: url(../assets/projects/soilCamp/1.png) no-repeat center center/cover;
  }
  .tonyImgBef::before {
    background: url(../assets/projects/tony/social/2.jpg) no-repeat center center/cover;
  }
  .spaceImgBef::before {
    background: url(../assets/projects/space/2.png) no-repeat center center/cover;
  }
  .takeImgBef::before {
    background: url(../assets/projects/take/photos/15.png) no-repeat center center/cover;
  }
  .oomiyaImgBefore::before {
    background: url(../assets/projects/omyaabranding/1B.jpg) no-repeat center center/cover;
  }
  .webrewImgBef::before {
    background: url(../assets/projects/webbrew/webrew-pic.png) no-repeat center center/cover;
  }
  .soilvfxImgBef::before {
    background: url(../assets/projects/soilvfx/soil-pic.png) no-repeat center center/cover;
  }
  .chemistryImgBef::before {
    background: url(../assets/projects/chemistry/chem-pic.png) no-repeat center center/cover;
  }
  .nadiyaImgBef::before {
    background: url(../assets/projects/nadiyaithra/main.png) no-repeat center center/cover;
  }
  .plentyImgBef::before {
    background: url(../assets/projects/plentysld/plenty-pic.png) no-repeat center center/cover;
  }

  .backImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 390px;

    @media screen and (max-width: 550px) {
      height: 220px;
    }

    h1 {
      color: white;
      z-index: 5;
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: -1;
    background: rgba(0, 0, 0, 0.7);

    @media screen and (max-width: 550px) {
      height: 300px;
    }
  }

  .intro {
    font-size: 2rem;
    line-height: 1.5;
    text-align: center;

    @media screen and (max-width: 550px) {
      font-size: 1.3rem;
    }
    img{
      width: 90vw;
    }
  }

  .imge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .f {
      width: 46vw;
      height: 80vh;
      // margin: 1rem;
      margin-left: 0;

      @media screen and (max-width: 957px) {
        width: 40vw;
        height: 50vh;
      }

      @media screen and (max-width: 550px) {
        width: 100vw;
        flex: 0 0 50%;
        height: 160px;
      }

      img,
      video {
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding: 1rem;

        @media screen and (max-width: 650px) {
          padding: 0.2rem !important;
        }
      }
    }
    .jewarContain{
      img{ 
        object-fit: contain !important;
      }
    }
    .fluFlex{
      flex: 0 0 50% !important;
    }
    .fluFlextwo{
      height: 90vh;
    }

    .trb {
      height: auto !important;
    }

    @media screen and (max-width: 650px) {
      .invi {
        flex: 0 0 50%;
        height: 50vw;
        padding: 0.2rem;

        @media screen and (max-width: 650px) {
          flex: 0 0 100%;
        }
      }
    }

    .amb {
      margin: 0;
      height: auto;
      flex: 0 0 25%;

      img {
        padding: 0.5rem;
        object-fit: cover;
      }

      @media screen and (max-width: 550px) {
        width: 100vw;
        height: auto;
        flex: 0 0 50%;
      }
    }

    .fle {
      height: auto !important;
    }

    .ambre {
      flex: 0 0 33.33%;
      height: 500px;

      @media screen and (max-width: 650px) {
        height: 110px;
      }
    }
    .jewarHeight{
      height: 537px !important;
      video{
        object-fit: contain !important;
      }
    }

    .tony {
      height: auto;
    }
  }

  .fleImg {
    align-items: self-start;
  }

  .imgeTeaser {
    @media screen and (max-width: 650px) {
      flex-direction: row;
    }

    img,
    video {
      width: 100%;
      object-fit: cover;
    }
  }

  .inter {
    @media screen and (max-width: 650px) {
      flex: 0 0 50% !important;
    }

    video {
      @media screen and (max-width: 650px) {
        height: auto !important;
      }
    }
  }

  .resDiv {
    flex: 0 0 33% !important;

    @media screen and (max-width: 650px) {
      width: 92vw !important;
    }
  }

  .res2 {
    flex: 0 0 65% !important;
  }

  .res3 {
    flex: 0 0 30% I !important;
  }

  .res {
    @media screen and (max-width: 650px) {
      height: 258px !important;
    }
  }

  .vid {
    padding-top: 2rem;

    .f {
      flex: 0 0 25%;
      padding: 0.4rem;
      height: 500px;

      @media screen and (max-width: 650px) {
        flex: 0 0 50%;
        height: 170px;
        padding: 0;
      }
     
    }
    .jewarContain{
      img{
        object-fit: contain !important;
      }
    }
    .fluFlex{
      flex: 0 0 50% !important;
      // height: 80vh;
    }
    .fluFlexTwo{
      height: 80vh;
    }
      

    .soilcampHeight {
      flex: 0 0 33%;
    }

    .fcheck {
      img {
        object-fit: contain !important;
      }

      @media screen and (max-width: 650px) {
        height: 300px !important;
      }
    }

    .autoHeight {
      height: auto;
    }

    .soilcampHeight {
      height: 900px;
    }
  }

  .services {
    ul {
      display: flex;
      margin-left: 0;
      flex-wrap: wrap;

      li {
        font-size: 2rem;
        list-style-type: none;
        text-transform: lowercase;
        border: 1px solid black;
        border-radius: 25px;
        margin: 0.5rem;
        padding: 10px;
        font-size: 1.5rem;
      }
    }
  }

  .circle {
    border-radius: 50%;
  }

  .sqr {
    border-radius: 4rem;
  }

  .recproject {
    padding: 1rem;
    width: 22vw;
    height: 22vw;
    margin: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    @media screen and (max-width: 700px) {
      width: 88vw;
      height: 100vw;
    }

    h1 {
      font-size: 1.5rem;
      padding: 1rem 0;
    }

    img {
      width: 100%;
      height: 77%;
      object-fit: cover;
    }
  }
}

// figo logo
.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;

  @media screen and (max-width: 940px) {
    flex-direction: column;
    padding: 0;
  }

  .imageLogo {
    width: 80vw;
    height: 40vw;

    @media screen and (max-width: 940px) {
      width: 100vw;
      height: 60vw;
      padding: 2rem;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ambImgLogo {
    height: 40vw;
  }

  .logoVid {
    width: 48vw;
    height: 40vw;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 940px) {
      padding: 0 2rem;
      width: 100vw;
      flex-direction: row;
    }

    video,
    img {
      width: 100%;
      object-fit: fill;
      height: 50%;

      @media screen and (max-width: 940px) {
        height: 100%;
        padding: 0.1rem;
      }
    }
  }

  .goyalsocial {
    video {
      height: 100%;
    }
  }

  .ambImgLogo {
    width: 65vw;

    @media screen and (max-width: 940px) {
      width: 100vw !important;
      height: 300px;
      padding: 0 2rem;

      @media screen and (max-width: 650px) {
        padding: 0 1.5rem;
      }
    }
  }

  .ambLogoVid {
    height: 40vw;
    flex-wrap: wrap;

    @media screen and (max-width: 940px) {
      height: auto;

      @media screen and (max-width: 450px) {
        flex-direction: row;
        padding: 0 1.5rem;
      }
    }

    img {
      width: 50%;
      padding: 0.2rem;
      object-fit: cover;

      @media screen and (max-width: 940px) {
        height: 200px;

        @media screen and (max-width: 450px) {
          padding: 0.2rem;
          // padding-top: 1rem;
        }
      }
    }
  }
  .goyaSocialVid{
    video{
      width: auto !important;
    }
  }
}

// Home page navbar
.innerHome {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.innerHome a {
  font-size: 2.5rem;
  font-family: Impact, "Anton", Haettenschweiler, "Arial Narrow Bold",
    sans-serif;
}

// custom

.fset {
  height: 370px !important;

  @media screen and (max-width: 650px) {
    height: 200px !important;
  }
}

.ambset3 {
  flex: 0 0 65% !important;
  height: auto !important;
}

.ambset2 {
  flex: 0 0 30% !important;
  height: auto !important;
}

.figoImgLogo {
  height: 114vh !important;
  width: 100% !important;

  @media screen and (max-width: 900px) {
    width: 100vw !important;
    height: 70vw !important;
  }
}

.figoVidLogo {
  height: 114vh !important;

  @media screen and (max-width: 700px) {
    padding: 0 2rem;
    width: 100vw;
    height: 150px !important;

    video {
      width: 50% !important;
    }
  }
}

.rellamb {
  width: 25vw !important;

  @media screen and (max-width: 700px) {
    height: auto !important;
  }
}

.archiStyle {
  width: auto !important;

  @media screen and (max-width: 700px) {
    height: auto !important;
    width: 100vw !important;
  }
}

.smallP {
  font-size: 11px !important;
  padding-top: 12px;
}

@media screen and (max-width: 700px) {
  .goyaImg {
    flex-direction: column !important;

    .ambre {
      width: 100% !important;

      video {
        height: 85vh !important;
      }
    }
  }
}