.service {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f5e7;
  overflow: hidden;
  position: sticky;
  border-top: 3px solid white;
  top: 0;
  z-index: 8;
  justify-content: center;

  .timeLine {
    padding-top: 0rem;

    @media screen and (max-width: 600px) {
      padding-top: 3rem;
    }
  }

  .services {
    text-align: right;

    @media screen and (max-width: 700px) {
      padding-top: 3rem;
    }

    .tab {
      width: 50%;
      display: inline-block;

      @media screen and (max-width: 954px) {
        width: 80%;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
      }

      .hide {
        display: inline-block;
        list-style: none;
        padding: 0.5rem;
        border: 1px solid rgb(156, 156, 156);
        border-radius: 1rem;
        font-size: 1.5rem;
        margin: 0.5rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: 954px) {
          font-size: 1rem;
          padding: 0.2rem 0.5rem;
          line-height: 20px;
        }
      }
    }
  }
}