.home_title {
  min-height: 85vh;
  // padding-top: 2rem;
  position: relative;
  position: sticky;
  top: 93px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 806px) {
    top: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .name {
    width: 98vw !important;
    @media screen and (max-width: 600px) {
      height: 17vh;
      justify-content: center;
      // align-items: center;
      padding-top: 2rem;
    }
  }

  h1 {
    font-family: Impact, "Anton", Haettenschweiler, "Arial Narrow Bold",
      sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 15vw;
    position: relative;

    @media screen and (max-width: 600px) {
      font-size: 15vw;
    }
  }

  span {
    border: 2px solid black;
    border-radius: 50%;
    display: inline-block;
    font-weight: 600;
    height: 40px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    height: 32px;
    width: 32px;
    padding: 8px;
  }

  .description p {
    font-family: "Lato";
    line-height: 40px;
    font-size: 1.5rem;
    text-align: justify;

    @media screen and (max-width: 806px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 600px) {
      line-height: 1.5;
      // font-size: 1.2rem;
      hyphens: auto;
    }
  }

  .componentTitle {
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600px) {
      padding-top: 3rem;
    }

    span {
      width: 30px;
      margin: 2px;
      border-width: 1px;
    }

    p {
      display: inline;
      border: 1px solid black;
      padding: 5px;
      border-radius: 5px;
      font-weight: bold;
    }
  }
}

.homecam {
  display: flex;
  justify-content: center;
  background-color: #f7f3e5;
  overflow: hidden;
  // align-items: center;
  flex-direction: column;
  position: sticky;
  height: 100vh;
  top: 0;
  // padding-top: 2rem;
  border-top: 3px solid white;
  z-index: 5;

  .timeLine {
    float: right;
  }

  .cam {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      padding-top: 0;
      .imgBox {
        height: auto;
        padding: 2rem;
        width: 70vw;
      }
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    padding-top: 1rem;

    .container {
      display: unset;
    }
  }

  .imgBox {
    flex: 1 1 0%;
    padding-right: 1rem;
    height: 560px;

    @media screen and (max-width: 700px) {
      padding-right: 0;
      padding-left: 0;
      flex: unset;
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      // object-fit: cover;
      // border: 1px solid black;
    }
  }

  .imgContent {
    flex: 1 1 0%;
  }

  div {
    // flex: 1 1 0%;
    padding: 0px;
    // font-size: 1.5rem;
    text-align: justify;

    @media screen and (max-width: 600px) {
      padding-left: 0;
      padding-right: 0;
    }

    p {
      
      font-size: 1.5rem;
      line-height: 1.5;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
        // line-height: 1.5;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .hidee {
    display: none;
  }
}